import React, { useEffect, useState } from "react"
import Nav from "../components/nav"
import Layout from "../components/layout"
import ForgetPassPopup from "../components/Authentication/ForgetPassPopup"
import Otp from "../components/Authentication/Otp"
import Recovery from "../components/Authentication/Recovery"

function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  function emailChangeHandler(event) {
    setEmail(event.target.value)
  }
  function passwordChangeHandler(event) {
    setPassword(event.target.value)
  }
  const [forgetStatus, setForgetStatus] = useState(false)
  const [otpStatus, setOtpStatus] = useState(false)
  const [recoveryStatus, setRecoveryStatus] = useState(false)

  function buttonClick(event) {
    event.preventDefault()
    setEmail("")
    setPassword("")
  }

  return (
    <Layout title="Log In">
      <div className="content mx-auto">
        <div className="flex flex-col md:flex-row justify-between pb-20 pt-56 px-10 lg:px-0">
          <div className="contentWrapper">
            <div className="content">
              <div className="content-right">
                <div className="heading">Lorem Ipsum Dolor Sit</div>
                <div className="heading">Amet Unde</div>

                <hr className="line" />

                <p className="content-para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <p className="content-para">
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat
                  nulla pariatur. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id
                  est laborum.
                </p>
                <div className="content-bitton-div">
                  <button className="content-button">CTA</button>
                </div>
              </div>
            </div>
            <ForgetPassPopup
              isOpen={forgetStatus}
              onClose={() => setForgetStatus(false)}
              openOtp={() => setOtpStatus(true)}
            />
            <Otp
              isOpen={otpStatus}
              onClose={() => setOtpStatus(false)}
              openRecovery={() => setRecoveryStatus(true)}
            />
            <Recovery
              isOpen={recoveryStatus}
              onClose={() => setRecoveryStatus(false)}
            />
            <div className="form-data">
              <div className="form-data-login">
                <p className="margin-4-per heading">Log In</p>

                <p margin-2-per>
                  Do not have an acoount?{" "}
                  <span className="SignUp">
                    <a href="#">Sign Up</a>
                  </span>
                </p>
              </div>
              <div className="third-party-button">
                <button className="google">Log in with Google</button>
                <button className="facebook">Log in with Facebook</button>
                <button className="twitter">Log in with Twitter</button>
              </div>
              <div className="OR">
                <div className="left-div"></div>
                OR
                <div className="right-div"></div>
              </div>
              <div Name="input-field">
                <div className="user-inputs">
                  <label for="email" className="label-field-email">
                    Email id*:
                  </label>
                  <input
                    className="input-field-email"
                    type="text"
                    placeholder="Your Email"
                    value={email}
                    onChange={emailChangeHandler}
                  />
                  <label for="password" className="label-field-password">
                    Password*
                  </label>
                  <input
                    className="input-field-password"
                    placeholder="**********"
                    type="password"
                    value={password}
                    onChange={passwordChangeHandler}
                  />
                  <span className="forgetPassword">
                    <button
                      onClick={() => {
                        setForgetStatus(true)
                      }}
                    >
                      Forget Password
                    </button>
                  </span>

                  <button className="login-button" onClick={buttonClick}>
                    Log in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Login
