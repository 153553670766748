import React from "react";

function Otp(props){

  const { isOpen, onClose, openRecovery ,children } = props
  const transitionTime = 0.4
  return (
    <div
      style={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        top: "0",
        left: "0",
        transition: transitionTime + "s",
        opacity: isOpen ? "1" : "0",
        pointerEvents: isOpen ? "all" : "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "arial",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          background: "rgba(0,0,0,0.5)",
        }}
        onClick={onClose || (() => {})}
      ></div>
      <div
        style={{
          position: "absolute",
          top: isOpen ? "50%" : "45%",
          left: "50%",
          transform: `translateX(-50%) translateY(-50%)`,
          background: "#fff",
          padding: "2%",
          borderRadius:"8px",
          transition: transitionTime + "s",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            
          }}
        >
          
            <div
              style={{display:"flex",
              flexDirection:"column",
              alignContent:"center",
              textAlign: "center",
              padding:"6%",
              }}>
              <p className="forget-Password-header">Enter OTP</p>
            <p>We have shared OTP on your email/phone<br/>please enter OTP below</p>
            <input className="input-field-email" placeholder="*********"></input>
            <button className="login-button" onClick={()=>{
                onClose();
                openRecovery();
            }}>Verify</button>
            </div>
          
        </div>
      </div>
    </div>
  )


    return(
        <div>
            
        </div>
    )
}

export default Otp;